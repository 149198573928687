/* Morris charts here */
var mcharts = function () {

    return {
        MorrisDonut1: function () {
            Morris.Donut({
                element: 'donut-1',
                data: [
                    {label: "Download Sales", value: 121},
                    {label: "In-Store Sales", value: 124},
                    {label: "Mail-Order Sales", value: 120}
                ],
                colors: ['#525878', '#BD9B33', '#47C0C2']
            });
        },
        MorrisDonut2: function () {
            Morris.Donut({
                element: 'donut-2',
                data: [
                    {label: "Your data", value: 45},
                    {label: "More data", value: 24},
                    {label: "Another data", value: 21},
                    {label: "Some more data", value: 24},
                    {label: "Small data", value: 12}
                ],
                colors: ['#525878', '#AFD8F8','#55AA51','#F26A5C','#47C0C2']
            });
        },
        MorrisDonut3: function () {
            Morris.Donut({
                element: 'donut-3',
                data: [
                    {label: "Returning visitors", value: 45},
                    {label: "New visitors", value: 24},
                    {label: "Robots", value: 21}
                ],
                colors: ['#82c1ec', '#F26A5C','#b4b4b4']
            });
        },
        /* Donut chart starts */

        MorrisDonut4: function () {
            Morris.Donut({
                element: 'donut-2',
                data: [
                    {label: "Special sales", value: 87},
                    {label: "Bundle sales", value: 13}
                ],
                colors: ['#ff6c60', '#343a4b']
            });
        },
        CurveChart: function () {
            Morris.Line({
                element: 'curve-chart',
                data: [
                    { y: '2006', a: 100, b: 90 },
                    { y: '2007', a: 75,  b: 65 },
                    { y: '2008', a: 30,  b: 20 },
                    { y: '2009', a: 75,  b: 65 },
                    { y: '2010', a: 50,  b: 40 },
                    { y: '2011', a: 75,  b: 65 },
                    { y: '2012', a: 100, b: 90 },
                    { y: '2013', a: 75,  b: 165 },
                    { y: '2014', a: 20, b: 36 }
                ],
                xkey: 'y',
                ykeys: ['a', 'b'],
                labels: ['Product A', 'Product B'],
                lineColors: ['#525878', '#F26A5C']
            });
        },
        MorrisArea: function () {
            Morris.Area({
                element: 'area-1',
                data: [
                    { y: '2006', a: 100, b: 90, c: 15 },
                    { y: '2007', a: 75,  b: 65, c: 25  },
                    { y: '2008', a: 50,  b: 40, c: -47  },
                    { y: '2009', a: 75,  b: -65, c: 46  },
                    { y: '2010', a: 50,  b: -40, c: 36  },
                    { y: '2011', a: 75,  b: 65, c: -68  },
                    { y: '2012', a: 100, b: 90, c: 78  },
                    { y: '2013', a: 75,  b: 65, c: 56  },
                    { y: '2014', a: 100, b: 90, c: 65 }
                ],
                xkey: 'y',
                ykeys: ['a', 'b', 'c'],
                labels: ['Series A', 'Series B', 'Series C'],
                events: ['2008-05-11', '2011-02-01', '2012-03-01'],
                fillOpacity: '0.75',
                lineColors: ['#47C0C2', '#F26A5C', '#525878']
            });
        }
    };

}();