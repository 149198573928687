
/*Sparklines */

/* Sparkline plugin Section starts */

$("#status1").sparkline([5,6,7,9,9,5,9,6,5,6,6,7,7,6,7,8,9,5 ], {
    type: 'line',
    width: '80',
    height: '20',
    lineColor: '#0ca5e7',
    fillColor: '#e5f3f9'});

$("#status2").sparkline([5,6,7,4,9,5,9,6,4,6,6,7,8,6,7,4,9,5 ], {
    type: 'line',
    width: '80',
    height: '20',
    lineColor: '#0ca5e7',
    fillColor: '#e5f3f9'});

$("#status3").sparkline([9,6,7,5,9,5,9,7,5,6,3,7,7,6,7,8,8,5 ], {
    type: 'line',
    width: '80',
    height: '20',
    lineColor: '#0ca5e7',
    fillColor: '#e5f3f9'});

$("#status4").sparkline([5,2,7,9,9,4,9,6,5,9,6,7,5,6,7,8,4,5 ], {
    type: 'line',
    width: '80',
    height: '20',
    lineColor: '#0ca5e7',
    fillColor: '#e5f3f9'});

$("#status5").sparkline([7,6,3,9,9,5,4,6,6,9,6,7,7,6,4,8,9,5 ], {
    type: 'line',
    width: '80',
    height: '20',
    lineColor: '#0ca5e7',
    fillColor: '#e5f3f9'});

$("#status6").sparkline([4,6,7,8,9,5,3,6,5,6,7,5,7,2,7,8,7,5 ], {
    type: 'line',
    width: '80',
    height: '20',
    lineColor: '#0ca5e7',
    fillColor: '#e5f3f9'});

$("#status7").sparkline([3,6,3,9,9,5,4,6,5,6,4,9,7,6,7,8,8,5 ], {
    type: 'line',
    width: '80',
    height: '20',
    lineColor: '#0ca5e7',
    fillColor: '#e5f3f9'});
	
$("#todayspark1").sparkline([8,11,9,12,10,9,13,11,12,11,10,12,11,], {
    type: 'bar',
    height: '50',
    barWidth: 7,
    barColor: '#fff'});

$("#todayspark2").sparkline([7,11,9,12,8,9,14,11,10,12,10,12,11,], {
    type: 'bar',
    height: '50',
    barWidth: 7,
    barColor: '#fff'});

$("#todayspark3").sparkline([3,6,3,9,9,5,4,6,5,6,4,9,4,6,3,7,8,9,7,5,8,9,5,7,8,8,5 ], {
    type: 'line',
    width: '170',
    height: '50',
    lineColor: '#fff',
    fillColor: '#ddd'});

/* Sparkline plugin section ends */