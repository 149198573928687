/* JS */


/* Navigation */

$(document).ready(function(){

  $(window).resize(function()
  {
    if($(window).width() >= 765){
      $(".sidebar .sidebar-inner").slideDown(350);
    }
    else{
      $(".sidebar .sidebar-inner").slideUp(350); 
    }
  });

});

$(document).ready(function(){

  $(".has_submenu > a").click(function(e){
    e.preventDefault();
   
    var menu_li = $(this).parent("li");
    var menu_ul = $(this).next("ul");

    if(menu_li.hasClass("open")){
      menu_ul.slideUp(350);
      menu_li.removeClass("open")
    }
    else{
      $(".navi > li > ul").slideUp(350);
      $(".navi > li").removeClass("open");
      menu_ul.slideDown(350);
      menu_li.addClass("open");
    }
  });


  // ToD Mobile menu
  $(".sidebar-dropdown a").on('click',function(e){
      e.preventDefault();

      if(!$(this).hasClass("dropy")) {
        // hide any open menus and remove all other classes
        $(".sidebar .sidebar-inner").slideUp(350);
        $(".sidebar-dropdown a").removeClass("dropy");
        
        // open our new menu and add the dropy class
        $(".sidebar .sidebar-inner").slideDown(350);
        $(this).addClass("dropy");
      }
      
      else if($(this).hasClass("dropy")) {
        $(this).removeClass("dropy");
        $(".sidebar .sidebar-inner").slideUp(350);
      }
  });

    //set sidebar-inner height
    var $newheight = $(document).height() - $('body').offset().top - 62;
    $('.sidebar-inner').css('height',$newheight);


    //sidebar hide
    $('.sidebar-inner-bottom > i').on('click', function() {
        var $sidebar = $('.sidebar');
        var $mainbar = $('.mainbar');
        $sidebar.toggleClass('sidebar-narrow');
        $mainbar.toggleClass('mainbar-wide');
        $sidebar.animate({
            'width' : $sidebar.css('width') == '70px' ? '240px' : '70px'
        }, 500);
        $mainbar.animate({
            'margin-left' : $mainbar.css('margin-left') == '70px' ? '240px' : '70px'
        }, 500);
    });

    // Accordion
    $("#accordion .panel:not(:first-child) .panel-title > a").addClass("collapsed");

    $("#accordion .panel-heading").each(function() {
        if ( !$(this).find(".panel-title > a").hasClass("collapsed")) {
            $(this).toggleClass("active");
        }
    });



    $("#accordion .panel-heading").find(".panel-title > a").on('click', function() {
            if ( !$(this).hasClass("collapsed")) {
                $(this).parent().parent().removeClass("active");
            } else {
                $("#accordion .panel-heading").each(function() {
                    if ( !$(this).find(".panel-title > a").hasClass("collapsed")) {
                        $(this).removeClass("active");
                    }
                });
                $(this).parent().parent().addClass("active");
            }
        });
});

$(window).resize(function(){
//set sidebar-inner height
var $newheight = $(document).height() - $('body').offset().top - 62;
$('.sidebar-inner').css('height',$newheight);
});

/* Widget close */

$('.wclose').click(function(e){
  e.preventDefault();
  var $wbox = $(this).parent().parent().parent();
  $wbox.hide(100);
});

/* Widget minimize */

$('.wminimize').click(function(e){
	e.preventDefault();
	var $wcontent = $(this).parent().parent().next('.widget-content');
	if($wcontent.is(':visible'))
	{
	  $(this).children('i').removeClass('fa fa-chevron-up');
	  $(this).children('i').addClass('fa fa-chevron-down');
	}
	else 
	{
	  $(this).children('i').removeClass('fa fa-chevron-down');
	  $(this).children('i').addClass('fa fa-chevron-up');
	}            
	$wcontent.toggle(500);
}); 

/* Progressbar animation */

setTimeout(function(){

	$('.progress-animated .progress-bar').each(function() {
		var me = $(this);
		var perc = me.attr("data-percentage");

		var current_perc = 0;

		var progress = setInterval(function() {
			if (current_perc>=perc) {
				clearInterval(progress);
			} else {
				current_perc +=1;
				me.css('width', (current_perc)+'%');
			}

			me.text((current_perc)+'%');

		}, 600);

	});

},600);

/* Scroll to Top */


$(".totop").hide();

$(function(){
	$(window).scroll(function(){
	  if ($(this).scrollTop()>300)
	  {
		$('.totop').fadeIn();
	  } 
	  else
	  {
		$('.totop').fadeOut();
	  }
	});

	$('.totop a').click(function (e) {
	  e.preventDefault();
	  $('body,html').animate({scrollTop: 0}, 500);
	});
});

/* Sidebar calendar */

$(function() {
	$( "#todaydate" ).datepicker();
});

/* Modal fix */

$('.modal').appendTo($('body'));

/* Notification box */

$('.slide-box-head').click(function() {
    var $slidebtn=$(this);
    var $slidebox=$(this).parent().parent();
    if($slidebox.css('right')=="-252px"){
      $slidebox.animate({
        right:0
      },500);
      $slidebtn.children("i").removeClass().addClass("fa fa-chevron-right");
    }
    else{
      $slidebox.animate({
        right:-252
      },500);
      $slidebtn.children("i").removeClass().addClass("fa fa-chevron-left");
    }
}); 


$('.sclose').click(function(e){
  e.preventDefault();
  var $wbox = $(this).parent().parent().parent();
  $wbox.hide(0);
});


$('.sminimize').click(function(e){
	e.preventDefault();
	var $wcontent = $(this).parent().parent().next('.slide-content');
	if($wcontent.is(':visible')) 
	{
	  $(this).children('i').removeClass('fa fa-chevron-down');
	  $(this).children('i').addClass('fa fa-chevron-up');
	}
	else 
	{
	  $(this).children('i').removeClass('fa fa-chevron-up');
	  $(this).children('i').addClass('fa fa-chevron-down');
	}            
	$wcontent.toggle(0);
});


  